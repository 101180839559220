<template>
  <div class="names-filter p-2 ag-filter d-flex flex-column" :class="{ active: open }">
    <v-select
      v-model="selected"
      ref="vue-select"
      label="text"
      maxHeight="300px"
      v-on:input="onChange($event)"
      multiple
      :options="options"
      :placeholder="$t('agGrid.filters.selectNames')"
      :clearable="false"
      :clearSearchOnSelect="false"
      :getOptionKey="option => option.id"
      @search:focus="open = true"
      @search:blur="open = false"
    ></v-select>
    <!-- :reduce="opt => opt.value" -->

    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <button type="button" @click="resetFilter">{{ $t('agGrid.localeText.resetFilter') }}</button>
      <button type="button" @click="setFilter">{{ $t('agGrid.localeText.applyFilter') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import api from '@/services/api';

export default {
  name: 'vue-select-names-filter',
  data() {
    return {
      selected: [],
      text: [],
      valueGetter: null,
      options: [],
      open: false,
    };
  },
  beforeMount() {
    this.setOptions();
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  computed: {
    ...mapGetters({
      allOperations: types.GETTER_PARTNER_OPERATIONS,
    }),
  },
  methods: {
    async setOptions() {
      const { data: response } = await api.get('/order/finances/names');
      const nameOptions = response.data.map(opt => ({
        value: opt,
        text: opt,
        operation: 'delivery',
      }));
      this.options = nameOptions;
    },
    setFilter() {
      this.text = this.selected.map(opt => opt.value);
    },
    resetFilter() {
      this.selected = [];
      this.text = [];
      this.setModel(null);
    },
    isFilterActive() {
      return (
        this.text !== null && this.text !== undefined && this.text !== '' && !!this.text.length
      );
    },
    doesFilterPass(params) {
      this.$refs['vue-select'].search = '';
      return this.text.find((t, index) => {
        const option = this.getOption(t, this.selected[index].operation);
        return !t || option.value === this.valueGetter(params.node);
      });
    },
    getModel() {
      const operations = [];
      const text = this.text
        ? this.text
            .map((t, index) => {
              operations.push(this.selected[index].operation);
              return this.getOption(t, this.selected[index].operation).text;
            })
            .join(', ')
        : null;

      return {
        value: this.text && this.text.length ? this.text : null,
        text,
        state: true,
        filterType: 'state',
        operations,
      };
    },
    getOption(value, operation = 'delivery') {
      const status = this.options.find(opt => opt.value === value && opt.operation === operation);
      return status;
    },
    onChange() {
      this.$refs['vue-select'].search = '';
    },
    setModel(model) {
      if (model !== '@reset' && model !== null) {
        this.text = model.value;
      }

      if (model === '@reset' || model === null) {
        this.text = [];
        this.selected = [];
        this.$refs['vue-select'].search = '';
      }
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 300px;
.names-filter {
  width: 350px;
  transition: height 200ms ease;
  overflow: hidden;
  .vs__dropdown-menu {
    max-height: 200px;
  }

  &.active {
    height: 250px;
  }
}
</style>

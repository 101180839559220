<template>
  <div class="uello-app-users-management h-100 mt-5">
    <div class="d-flex justify-content-end align-items-center px-3 pt-4 mb-3">
      <!--h2 class="text-dark-darken pt-4 font-weight-bold">
        {{ $t('pageTitles.finances') }}
      </h2-->
      <uello-button
        class="fix-height ml-4 fix-width"
        color="gray"
        icon="file-chart"
        :title="$t('form.buttons.generateCSV')"
        :disabled="loading"
        @click="onCsvPrint"
        :text="$t('form.buttons.generateCSV')"
      ></uello-button>
    </div>
    <div
      v-show="info.start !== ''"
      class="d-flex align-items-center justify-content-between px-3 mb-2"
    >
      <span><span class="info-box">Período: </span>{{ info.start }} à {{ info.end }}</span>
      <span
        ><span class="info-box">Valor Total: </span
        >{{
          `R$ ${info.value
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')}`
        }}</span
      >
    </div>
    <dataset-ag-grid
      ref="finance"
      :setData="() => {}"
      @grid-ready="onGridReady"
      :defs="defs"
      :rowData="rowData"
      :dataComponents="dataComponents"
      :dataFwComponents="fwComponents"
      :pagination="pagination"
      v-on:on-paginate="onPaginate"
      v-on:on-change-filter="onChangeFilter"
    />

    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import api from '@/services/api';
import { movidesk } from '@/mixins';
import i18n from '@/i18n';
import { UelloButton } from '@uello/componentello';

import MoneyCellRender from './components/u-finances/FinanceMoneyCellRender.vue';
import StatusCellRender from './components/u-finances/FinanceStatusCellRender.vue';
import StatusFilter from './components/u-finances/StatusFilter.vue';
import NamesFilter from './components/u-finances/NamesFilter.vue';

export default {
  name: 'uello-app-finances',
  mixins: [movidesk],
  components: {
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
    UelloButton,
  },
  data() {
    return {
      movideskHasButton: true,
      createUserOpen: false,
      submitButtonLabel: 'submitButtonEditUser',
      loading: false,
      gridApi: null,
      columnApi: null,
      create: false,
      userId: null,
      rowData: [],
      dataComponents: {
        MoneyCellRender,
        StatusCellRender,
        StatusFilter,
        NamesFilter,
      },
      info: {
        value: 0,
        start: '',
        end: '',
      },
      fwComponents: {
        moneyCellRender: 'MoneyCellRender',
        statusCellRender: 'StatusCellRender',
        statusFilter: 'StatusFilter',
        namesFilter: 'NamesFilter',
      },
      pagination: {
        current_page: 1,
        per_page: 15,
        totalItems: 0,
      },
      start: '',
      end: '',
      defs: [
        {
          headerName: 'Id',
          field: 'id',
          sortable: false,
          filter: 'textFilter',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('agGrid.columnDefs.status'),
          field: 'status',
          sortable: false,
          filter: 'statusFilter',
          cellStyle: { textAlign: 'center' },
          cellRenderer: 'statusCellRender',
        },
        {
          headerName: i18n.t('form.labels.name'),
          field: 'name',
          sortable: false,
          filter: 'namesFilter',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('form.labels.nfeNumber'),
          field: 'nfe',
          sortable: false,
          filter: 'textFilter',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('orderEditModals.infoDetails.volumeNumber'),
          field: 'volumes',
          sortable: false,
          filter: false,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('orderEditModals.infoDetails.delivered_data'),
          field: 'finishedAt',
          sortable: false,
          filter: 'dateFilter',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Valor',
          field: 'value',
          sortable: false,
          filter: false,
          cellStyle: { textAlign: 'center' },
          cellRenderer: 'moneyCellRender',
        },
      ],
      rowSelected: null,
    };
  },
  mounted() {
    this.getData();
    this.preloadNames();
  },
  methods: {
    onGridReady({ api: gridapi, columnApi }) {
      this.gridApi = gridapi;
      this.columnApi = columnApi;
      this.gridApi.setRowData([]);
      this.gridApi.hideOverlay();
      gridapi.sizeColumnsToFit();
    },
    async preloadNames() {
      await api.get('/order/finances/names');
    },
    async getData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const { data: response } = await api.get('/order/finances/current', {
        params: {
          page: this.pagination.current_page,
          limit: this.pagination.per_page,
          start: this.start,
          end: this.end,
        },
      });
      this.rowData = response.data.orders;
      this.pagination.totalItems = response.data.info.orders;
      this.info.value = response.data.info.totalValue;
      this.info.start = response.data.info.start;
      this.info.end = response.data.info.end;
      this.loading = false;
    },
    async onCsvPrint() {
      await api
        .get('/order/finances/current', {
          params: {
            page: 1,
            limit: 0,
            start: this.start,
            end: this.end,
          },
        })
        .then(({ data }) => {
          const { orders } = data.data;
          let csvContent = 'data:text/csv;charset=utf-8,';
          const firstLine = 'Id,Status,Nome,nº NF-E,Total Volumes,Finalizado Em,Valor';
          csvContent += [firstLine, this.handleData(orders)];

          const linkData = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', linkData);
          link.setAttribute('download', 'export.csv');
          link.click();
        });
    },
    handleData(data) {
      const result = data.map(entry => {
        let string = '\n';
        string = `${string}${entry.id},`;
        string = `${string}${entry.status.name},`;
        string = `${string}${entry.name},`;
        string = `${string}${entry.nfe},`;
        string = `${string}${entry.volumes},`;
        string = `${string}${entry.finishedAt},`;
        string = `${string}R$ ${(Math.round(entry.value.total * 100) / 100).toFixed(2)}`;
        return string;
      });
      return result;
    },
    dateFormat(data) {
      return data.lastLoginAt
        ? format(parseISO(data.lastLoginAt.date), "dd/MM/yyyy' - 'HH:mm:ss")
        : 'N/A';
    },
    onPaginate(page, perPage) {
      this.pagination.current_page = page;
      this.pagination.per_page = perPage;

      this.getData();
    },
    onChangeFilter(filters) {
      filters.forEach(item => {
        if (item.field === 'finishedAt') {
          this.start = item.value.from;
          this.end = item.value.to;
        }
      });

      if (filters.length === 0) {
        this.start = '';
        this.end = '';
      }

      this.pagination.current_page = 1;
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.pagination-options {
  right: 0;
  transform: translateY(-5px);

  span,
  select {
    display: block;
    min-width: 160px;
  }
}

.info-box {
  user-select: none;
}
</style>

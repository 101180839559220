<template>
  <div class="status-filter p-2 ag-filter d-flex flex-column" :class="{ active: open }">
    <v-select
      v-model="selected"
      ref="vue-select"
      label="text"
      maxHeight="300px"
      multiple
      :options="options"
      :placeholder="$t('agGrid.filters.selectStatus')"
      :clearable="false"
      :clearSearchOnSelect="false"
      :close-on-select="false"
      :clear-on-select="false"
      :getOptionKey="option => option.id"
      @search:focus="open = true"
      @search:blur="open = false"
    ></v-select>

    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <button type="button" @click="resetFilter">{{ $t('agGrid.localeText.resetFilter') }}</button>
      <button type="button" @click="toggle">{{ $t('agGrid.localeText.selectAll') }}</button>
      <button type="button" @click="setFilter">{{ $t('agGrid.localeText.applyFilter') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import * as entries from '@/config/financeStatusConfig';

export default {
  name: 'vue-select-status-filter',
  data() {
    return {
      selected: [],
      text: [],
      valueGetter: null,
      options: [],
      open: false,
    };
  },
  beforeMount() {
    this.setOptions();
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  computed: {
    ...mapGetters({
      allOperations: types.GETTER_PARTNER_OPERATIONS,
    }),
    selectedAll() {
      return this.selected.length === this.options.length;
    },
  },
  methods: {
    setOptions() {
      const statusOptions = entries.default.map(opt => ({
        value: opt.value,
        text: this.$t(opt.text),
        operation: 'delivery',
      }));
      this.options = [...statusOptions];
    },
    setFilter() {
      this.text = this.selected.map(opt => opt.value);
    },
    resetFilter() {
      this.selected = [];
      this.text = [];
      this.setModel(null);
    },
    isFilterActive() {
      return (
        this.text !== null && this.text !== undefined && this.text !== '' && !!this.text.length
      );
    },
    doesFilterPass(params) {
      return this.text.find((t, index) => {
        const option = this.getOption(t, this.selected[index].operation);
        return !t || option.value === this.valueGetter(params.node).code;
      });
    },
    getModel() {
      const operations = [];
      const text = this.text
        ? this.text
            .map((t, index) => {
              operations.push(this.selected[index].operation);
              return this.getOption(t, this.selected[index].operation).text;
            })
            .join(', ')
        : null;

      return {
        value: this.text && this.text.length ? this.text : null,
        text,
        state: true,
        filterType: 'state',
        operations,
      };
    },
    getOption(value, operation = 'delivery') {
      const status = this.options.find(opt => opt.value === value && opt.operation === operation);
      return status;
    },
    setModel(model) {
      if (model !== '@reset' && model !== null) {
        this.text = model.value;
      }

      if (model === '@reset' || model === null) {
        this.text = [];
        this.selected = [];
      }
    },
    toggle() {
      this.$nextTick(() => {
        this.selected = this.selectedAll ? [] : this.options.slice();
      });
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 300px;
.status-filter {
  width: 250px;
  transition: height 200ms ease;

  .vs__dropdown-menu {
    max-height: 300px;
  }

  &.active {
    height: 400px;
  }
}
</style>

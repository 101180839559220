export default [
  {
    value: 'so.fns',
    text: 'agGrid.filters.finalized',
  },
  {
    value: 'so.fnbl',
    text: 'agGrid.filters.canceledWithBilling',
  },
  {
    value: 'so.rdtp',
    text: 'agGrid.filters.returnedToPartner',
  },
];

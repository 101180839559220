<template>
  <div class="">
    <span>
      {{ formatAsPrice(this.params.value.total) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'finance-money-template',
  data() {
    return {
      id: null,
    };
  },
  methods: {
    formatAsPrice(value) {
      return `R$ ${value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')}`;
    },
  },
  beforeMount() {
    //
  },
  created() {
    this.id = this.params.value.id;
  },
};
</script>

<style lang="scss">
//
</style>

<template>
  <div>
    <span class="status-tag" v-bind:style="{ 'background-color': bgColor, color: fontColor }">
      {{ status }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'finance-status-template',
  data() {
    return {
      status: null,
      bgColor: '#fff',
      fontColor: '#fff',
    };
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case 'so.fns':
          this.bgColor = 'green';
          this.fontColor = '#f0f0f0';
          break;
        case 'so.fnbl':
          this.bgColor = 'yellow';
          this.fontColor = '#0f0f0f';
          break;
        case 'so.rdtp':
          this.bgColor = 'orange';
          this.fontColor = '#0f0f0f';
          break;
        default:
          this.bgColor = 'blue';
          this.fontColor = '#0f0f0f';
          break;
      }
    },
  },
  created() {
    this.status = this.params.value.name;
    this.statusColor(this.params.value.code);
  },
};
</script>

<style lang="scss">
.status-tag {
  padding: 5px 10px;
  border-radius: 8px;
  color: #f0f0f0;
}
</style>
